import api from '../services/api'

export const fetchData = async route => {
  const response = await api
    .get(`${route}`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.log('error ' + error)
    })
  return response
}

export const postData = async (route, data) => {
  const response = api
    .post(`${route}`, data)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.log('error ' + error)
    })
  return response
}

export const putData = async (route, data, id) => {
  const response = api
    .put(`${route}/${id}`, data)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.log('error ' + error)
    })
  return response
}

export const deleteData = (route, id) => {
  const response = api
    .delete(`${route}/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.log('error ' + error)
    })
  return response
}
