import React, { useState } from 'react'
import { Modal, Button } from 'antd'
import api from '../../services/api'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import styled from 'styled-components'

const getStoresNoOffers = async () => {
  const { data } = await api.get('../api_shop/paused')
  console.log("PAUSADAS: ", JSON.stringify(data))
  return data
}

const Stores = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Store = styled(Button)`
  margin: 6px;
`

export default function PausedStoresButton() {
  const [visible, setVisible] = useState(false)

  const { data, error, isLoading, isError } = useQuery(
    'stores?paused=true&limit=300',
    getStoresNoOffers,
  )

  const goToOffers = storeName => {
    localStorage.setItem('viewing_store', storeName)
  }

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  if (isLoading) {
    return ''
  }

  if (isError) {
    return 'Erro ao carregar lojas pausadas'
  }

  return (
    <>
      <Button
        type="link"
        onClick={showModal}
        style={{
          marginBottom: 16,
          marginTop: 16,
        }}
      >
        Lojas pausadas
      </Button>
      <Modal
        title="Lojas pausadas"
        visible={visible}
        layout="vertical"
        onCancel={handleCancel}
        footer={false}
        width="90%"
      >
        <Stores>
          {!error &&
            data.stores.map(store => (
              <Link
                to={`/loja/${store.slug}/ofertas`}
                onClick={() => goToOffers(store.title)}
              >
                <Store>{store.title}</Store>
              </Link>
            ))}
        </Stores>
      </Modal>
    </>
  )
}
