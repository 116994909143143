import React from 'react'
import { Badge } from 'antd'

const Counter = ({ min, max, length }) => {
  if (length < min) {
    return (
      <>
        {length} <Badge status="warning" />
      </>
    )
  }

  if (length > max) {
    return (
      <>
        {length} <Badge status="error" />
      </>
    )
  }

  return (
    <>
      {length} <Badge status="success" />
    </>
  )
}

export default Counter
