import React from 'react'
import { withRouter } from 'react-router'
import { PageHeader } from 'antd'
import styled from 'styled-components'
import TableTypes from '../../components/TableTypes'

const Content = styled.main`
  position: relative;
  margin: 24px;
  transition: all 0.2s;
`
const Types = () => (
  <Content>
    <PageHeader
      ghost={false}
      title="Tabela de Tipos"
      subTitle="Tipos existentes no sistema"
    />
    <TableTypes></TableTypes>
  </Content>
)

export default withRouter(Types)
