import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Row, Col, Card, PageHeader, Statistic } from 'antd'
import { fetchData } from '../../utils/request'

import useStores from '../../hooks/useStores'

import styled from 'styled-components'

import FeaturedStores from '../../components/FeaturedStores'
import Exporter from '../../components/Exporter'
const Content = styled.main`
  position: relative;
  margin: 24px;
  transition: all 0.2s;
`

async function fetchPlataformsFromServer() {
  const res = await fetchData(`/plataforms`)
  return res
}

async function fetchCategoriesFromServer() {
  const res = await fetchData(`/categories`)
  return res
}


const Dashboard = ({
  fetchPlataforms = fetchPlataformsFromServer,
  fetchCategories = fetchCategoriesFromServer
}) => {
  const [totalPlataforms, setTotalPlataforms] = useState(0)
  const [totalCategories, setTotalCategories] = useState(0)
  const { data, error, isLoading } = useStores()

  useEffect(
    function fetchCategoriesOnStart() {
      fetchCategories().then(function updateStateWithCategories(data) {
        setTotalCategories(data.length)
      })
    },
    [fetchCategories],
  )

  useEffect(
    function fetchPlataformsOnStart() {
      fetchPlataforms().then(function updateStateWithStores(data) {
        setTotalPlataforms(data.length)
      })
    },
    [fetchPlataforms],
  )

  if (error) {
    return 'Erro ao carregar'
  }

  if (isLoading) {
    return 'Carregando'
  }

  return (
    <Content>
      <Row gutter={[24, 16]}>
        <Col span={24}>
          <PageHeader
            ghost={false}
            title="Painel Administrativo DaazCavernas"
            subTitle="Seja bem vindo"
            extra={[
              <Exporter type="users">
                Baixar lista de usuários
              </Exporter>,
              <a
                href="https://www.daazcavernas.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                Acessar Site
              </a>,
              <a
                href="https://blog.daazcavernas.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                Acessar Blog
              </a>,
              <a
                href="https://www.notion.so/Wiki-DaazCavernas-a752328d1ae84452a6de913228760f76"
                target="_blank"
                rel="noreferrer noopener"
              >
                Acessar Notion
              </a>,
            ]}
          ></PageHeader>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Lojas cadastradas"
              value={data.total}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Plataformas"
              value={totalPlataforms}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Categorias"
              value={totalCategories}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <FeaturedStores />
        </Col>
      </Row>
    </Content>
  )
}

export default withRouter(Dashboard)
