import React, {useState} from 'react'
import { useQuery, useQueryCache, useMutation } from 'react-query'
import api from '../../../services/api'
import {
  Row, Col, Select, Button
} from 'antd'

const getCategories = async () => {
  const { data } = await api.get('categories?limit=999')
  return data
}

export default function AddCategory({storeId}) {
  const [selectedCategory, setSelectedCategory] = useState([])
  const { data, isLoading, isError } = useQuery('categories', getCategories)
  const cache = useQueryCache()
  const [mutatePostCategory] = useMutation(
    id =>
      api.post(`stores/${storeId}/category_stores`, {
        category_store: {
            category_id: id
        }
      }),
    {
      // Optimistically update the cache value on mutate, but store
      // the old value and return it so that it's accessible in case of
      // an error
      onMutate: () => {
        cache.cancelQueries('categories')

        const previousValue = cache.getQueryData('categories')

        cache.setQueryData('categories', old => [...old])

        return previousValue
      },
      // On failure, roll back to the previous value
      onError: (err, variables, previousValue) =>
        cache.setQueryData('categories', previousValue),
      // After success or failure, refetch the todos query
      onSettled: () => {
        cache.invalidateQueries('categories')
      },
    },
  )

  const handleChangeCategory = (value) => {
    setSelectedCategory(value)
  }

  if(isError)
  {
    return "Erro ao carregar select"
  }

  return (
    <Row justify="space-between">
      <Col span={16}>
        <Select
          allowClear
          style={{ width: '100%' }}
          placeholder="Selecione uma categoria"
          onChange={handleChangeCategory}
          loading={isLoading}
        >
          {
            !isLoading && data.map(category => <Select.Option key={category.id} value={category.id}>{category.title}</Select.Option>)
          }
        </Select>
      </Col>
      <Col span={6}>
        <Button type="primary" onClick={() => mutatePostCategory(selectedCategory)}>
          Adicionar
        </Button>
      </Col>
    </Row>
  )
}
