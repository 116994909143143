import React from 'react'
import { Layout } from 'antd'
const { Footer } = Layout
export default function FooterComponent() {
  return (
    <Footer style={{ textAlign: 'center' }}>
      DaazCavernas ©2020 Created by DaazCavernas{' '}
      <p style={{ color: '#ccc' }}>520 741 777 8</p>{' '}
    </Footer>
  )
}
