import React, { useState } from 'react'
import { Form, Modal, Button, Input, message } from 'antd'
import { postData } from '../../utils/request'

export default function NewStoreButton() {
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = e => {
    setVisible(false)
  }

  const onSubmit = async values => {
    const data = {
      store: {
        ...values,
      },
    }
    const response = await postData('/stores', data)

    if (response.id) {
      message.success('Loja criada')
    } else {
      message.error('Erro ao criar')
    }
  }

  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        style={{
          marginBottom: 16,
          marginTop: 16,
        }}
      >
        Adiciona nova loja
      </Button>
      <Modal
        title="Criando nova loja"
        visible={visible}
        layout="vertical"
        onCancel={handleCancel}
        footer={false}
      >
        <Form name={'form'} layout={'vertical'} form={form} onFinish={onSubmit}>
          <Form.Item label="Titulo" name="title">
            <Input />
          </Form.Item>
          <Form.Item label="URL" name="url">
            <Input />
          </Form.Item>
          <Form.Item label="Slug" name="slug">
            <Input />
          </Form.Item>
          <Form.Item style={{ width: '100%', marginTop: '15px' }}>
            <Button type="primary" htmlType="submit" block>
              Criar loja
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
