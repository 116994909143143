import React, { useState } from 'react'
import api, { apiUrl } from '../../services/api'
import axios from 'axios'
import { Card, Skeleton, List, Button, Select } from 'antd'
import { DeleteOutlined, LinkOutlined } from '@ant-design/icons'
import { useQuery, useQueryCache, useMutation } from 'react-query'

const { Option } = Select

const getFeaturedStores = async () => {
  const { data } = await api.get('popular_stores')
  return data
}

const getSitemapStores = async () => {
  const { data } = await axios.get(`${apiUrl}/sitemap`)
  return data
}

export default function FeaturedStores() {
  const { data, error, isLoading, isError } = useQuery(
    'popular_stores',
    getFeaturedStores,
  )
  const cache = useQueryCache()
  const [mutateDeletePopularStore] = useMutation(
    id => api.delete(`popular_stores/${id}`),
    {
      // Optimistically update the cache value on mutate, but store
      // the old value and return it so that it's accessible in case of
      // an error
      onMutate: () => {
        cache.cancelQueries('popular_stores')

        const previousValue = cache.getQueryData('popular_stores')

        cache.setQueryData('popular_stores', old => [...old])

        return previousValue
      },
      // On failure, roll back to the previous value
      onError: (err, variables, previousValue) =>
        cache.setQueryData('popular_stores', previousValue),
      // After success or failure, refetch the todos query
      onSettled: () => {
        cache.invalidateQueries('popular_stores')
      },
    },
  )

  if (isLoading) {
    return (
      <Card>
        Lojas Populares
        <Skeleton />
      </Card>
    )
  }

  if (isError) {
    return <span>Error: {error.message}</span>
  }

  return (
    <Card>
      Lojas Populares <br />
      <SelectStore filter={data} />
      <br />
      <List
        dataSource={data}
        renderItem={item => (
          <List.Item
            actions={[
              <DeleteOutlined
                onClick={() => mutateDeletePopularStore(item.id)}
              />,
            ]}
          >
            {item.store.title}{' '}
            <a
              href={`https://www.daazcavernas.com/cupom-desconto/${item.store.slug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkOutlined />
            </a>
          </List.Item>
        )}
      />
    </Card>
  )
}

const SelectStore = ({ filter }) => {
  const { data, isLoading } = useQuery('all_stores', getSitemapStores)
  const cache = useQueryCache()

  const [selectedStore, setSelectedStore] = useState('')

  const handleSelectChange = value => {
    setSelectedStore(value)
  }

  const [mutatePostPopularStore] = useMutation(
    id =>
      api.post('popular_stores', {
        popular_store: {
          store_id: id,
        },
      }),
    {
      // Optimistically update the cache value on mutate, but store
      // the old value and return it so that it's accessible in case of
      // an error
      onMutate: () => {
        cache.cancelQueries('popular_stores')

        const previousValue = cache.getQueryData('popular_stores')

        cache.setQueryData('popular_stores', old => [...old])

        return previousValue
      },
      // On failure, roll back to the previous value
      onError: (err, variables, previousValue) =>
        cache.setQueryData('popular_stores', previousValue),
      // After success or failure, refetch the todos query
      onSettled: () => {
        cache.invalidateQueries('popular_stores')
      },
    },
  )

  if (isLoading) {
    return <Skeleton.Input style={{ width: '100%' }} active />
  }

  const mapFilter = filter.map(store => store.store_id)

  const filteredOptions = data.filter(item => !mapFilter.includes(item.id))
  return (
    <div style={{ width: '100%', display: 'flex' }}>
      <Select
        showSearch
        loading={isLoading}
        onChange={handleSelectChange}
        style={{ width: '100%' }}
        placeholder="Selecione uma loja"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {filteredOptions.map(store => (
          <Option value={store.id}>{store.title}</Option>
        ))}
      </Select>
      <Button onClick={() => mutatePostPopularStore(selectedStore)}>
        Adicionar ao destaque
      </Button>
    </div>
  )
}
