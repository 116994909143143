import React, { useCallback } from 'react'
import { Form, Input, Select, InputNumber, message } from 'antd'
import { debounce } from 'lodash'
import { putData } from '../../utils/request'

export default function EditPlataformForm({ plataform, storeId }) {
  const [form] = Form.useForm()

  const { name } = plataform.plataform
  const { id: plataformId } = plataform

  const onSubmit = async values => {
    const { status, cpa, cpl } = values

    const data = {
      plataform_store: {
        status,
        cpa,
        cpl,
      },
    }

    try {
      await putData(`/stores/${storeId}/plataform_stores`, data, plataformId)
      message.success(`Plataforma ${name} atualizada`)
    } catch (error) {
      message.error('Erro ao atualizar dados')
    }
  }

  const debouncedSave = useCallback(
    debounce(nextValue => onSubmit(nextValue), 1000),
    [],
  )

  const handleChange = values => {
    debouncedSave(values)
  }

  return (
    <Form
      name={'form'}
      labelCol={null}
      wrapperCol={null}
      layout={'inline'}
      initialValues={plataform}
      form={form}
      onFinish={onSubmit}
      onValuesChange={handleChange}
    >
      <Form.Item label="Status" name="status" style={{ width: '30%' }}>
        <Select>
          <Select.Option value="Ativo">Ativo</Select.Option>
          <Select.Option value="Pausado">Pausado</Select.Option>
          <Select.Option value="Disponivel">Disponivel</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="CPA" name="cpa" style={{ width: '25%' }}>
        <InputNumber />
      </Form.Item>
      <Form.Item label="CPL" name="cpl" style={{ width: '25%' }}>
        <Input />
      </Form.Item>
    </Form>
  )
}
