import React, { useState, useEffect, useRef, useCallback } from 'react'
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Tag,
  Badge,
  Space,
  message,
  Switch,
  Divider,
  Menu,
  Dropdown,
} from 'antd'

import dayjs from 'dayjs'
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  LinkOutlined,
  TagOutlined,
  SettingFilled,
  DownOutlined,
  FileSearchOutlined,
  DollarCircleOutlined,
} from '@ant-design/icons'

import ColorHash from 'color-hash'
import EditPlataformExtraData from './EditPlataformExtraData'
import NewStoreButton from './NewStoreButton'
import EmptyStoresButton from './EmptyStoresButton'
import PausedStoresButton from './PausedStoresButton'
import Highlighter from 'react-highlight-words'
import { Link } from 'react-router-dom'
import { fetchData, deleteData, putData } from '../../utils/request'

async function fetchPlataformsFromServer() {
  const res = await fetchData('/plataforms')
  return res
}

async function fetchStoresFromServer(page = 1) {
  const res = await fetchData(`/stores?limit=10&page=${page}`)
  return res
}

async function fetchStoresSearchFromServer(value) {
  const res = await fetchData(`/stores?search=${value}`)
  return res
}

const colorHash = new ColorHash({ saturation: 0.4 })

const maxPaymentValue = (plataforms, type) =>
  plataforms.reduce((acc, value) => {
    const parseNumber =
      type === 'cpl' ? parseInt(parseCpl(value[type])) : value[type]
    return Math.max(acc, parseNumber)
  }, 0)

const parseCpl = value => {
  if (value === null) return '0'
  return value.replace(/^\D+/g, '')
}

const EditableCell = ({
  editing,
  dataIndex,
  inputType,
  record,
  index,
  children,
  itemName,
  availablePlataforms,
  ...restProps
}) => {
  const inputNode = () => {
    if (editing) {
      switch (itemName) {
        case 'status':
          const valueStatus = record.status
          return <Switch key={index} defaultChecked={valueStatus} />

        default:
          return <Input key={index} />
      }
    }
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={itemName}
          style={{
            margin: 0,
          }}
          key={record.id}
          valuePropName={itemName === 'status' ? 'checked' : 'value'}
        >
          {inputNode()}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

const EditableTable = ({
  fetchPlataforms = fetchPlataformsFromServer,
  fetchStores = fetchStoresFromServer,
  fetchSearch = fetchStoresSearchFromServer,
}) => {
  const [form] = Form.useForm()
  const [data, setData] = useState([])
  const [editingKey, setEditingKey] = useState('')
  const [plataforms, setPlataforms] = useState([])
  const [totalStores, setTotalStores] = useState(0)
  const [searchText, setSearchText] = useState(null)
  const [loading, setLoading] = useState(false)
  const searchInput = useRef(null)

  useEffect(() => {
    fetchPlataforms().then(function updateStatePlataforms(data) {
      setPlataforms(data)
    })
  }, [fetchPlataforms])

  useEffect(
    function fetchStoresOnStart() {
      if (searchText === null) {
        fetchStores().then(function updateStateWithStores(data) {
          console.log('data2: ', data.stores)
          setData(data.stores)
          setTotalStores(data.total)
        })
      }
    },
    [searchText, fetchStores],
  )

  const isEditing = record => record.id === editingKey
  const edit = record => {
    form.setFieldsValue({
      ...record,
    })
    setEditingKey(record.id)
  }

  const onChangePagination = useCallback(
    current => {
      fetchStores(current).then(function updateStateWithStores(data) {
        setData(data.stores)
      })
    },
    [fetchStores],
  )

  const cancel = () => {
    setEditingKey('')
  }

  const handleDelete = id => {
    const newData = data.filter(item => item.id !== id)
    setData(newData)
    deleteData('/stores', id)
    message.success('Excluido com sucesso')
  }

  const save = async key => {
    try {
      const row = await form.validateFields()
      let dataStore = { store: { ...row } }

      const response = await putData('/stores', dataStore, key)

      if (response.id) {
        message.success('Loja atualizada')
        const newData = [...data]
        const index = newData.findIndex(item => key === item.id)
        if (index > -1) {
          const item = response
          newData.splice(index, 1, { ...item, ...row })
          setData(newData)
          setEditingKey('')
        } else {
          newData.push(row)
          setData(newData)
          setEditingKey('')
        }
      } else {
        message.error('Erro ao atualizar')
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }

  const handleSearch = useCallback(
    (selectedKeys, confirm) => {
      confirm()
      if (selectedKeys.length > 0 && selectedKeys[0].length > 1) {
        setSearchText(selectedKeys[0])
        setLoading(true)
        setTimeout(() => {
          fetchSearch(selectedKeys[0]).then(function updateStateWithStores(
            data,
          ) {
            if (!data) {
              setData([])
              setLoading(false)
              setTotalStores(0)
              message.warning('Nada encontrado para sua busca')
              return
            }
            setTotalStores(data.total)
            setData(data.stores)
            setLoading(false)
          })
        }, 1000)
      } else {
        message.error('Insira ao menos 2 caracteres para buscar')
      }
    },
    [fetchSearch],
  )

  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
    fetchStores().then(function updateStateWithStores(data) {
      setData(data.stores)
      setTotalStores(data.total)
    })
  }

  const goToOffers = storeName => {
    localStorage.setItem('viewing_store', storeName)
  }

  const menuLoja = record => {
    return (
      <Menu>
        <Menu.Item icon={<FileSearchOutlined />}>
          <Link to={`/loja/${record.slug}/conteudo`}>Conteúdo</Link>
        </Menu.Item>
        <Menu.Item icon={<TagOutlined />}>
          <Link
            to={`/loja/${record.slug}/ofertas`}
            onClick={() => goToOffers(record.title)}
          >
            Ofertas
          </Link>
        </Menu.Item>
      </Menu>
    )
  }

  const menuPlataforma = (plataform_stores, record) => {
    return (
      <Menu>
        <Menu.Item icon={<EditOutlined />}>
          <EditPlataformExtraData
            plataforms={plataform_stores}
            storeId={record.id}
            storeName={record.title}
          />
        </Menu.Item>
      </Menu>
    )
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 15 }}>
        <Input
          ref={searchInput}
          placeholder="Buscar loja"
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Limpar
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined
        style={{ fontSize: '16px', color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select())
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  })

  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      rowKey: 'id',
      width: 80,
    },
    {
      key: 'status',
      title: '',
      dataIndex: 'status',
      width: 30,
      itemName: 'status',
      editable: true,
      render: (status, _) => (
        <div style={{ textAlign: 'center' }}>
          {status ? <Badge status="success" /> : <Badge status="error" />}
        </div>
      ),
    },
    {
      key: 'title',
      title: 'Título da Loja',
      dataIndex: 'title',
      itemName: 'title',
      editable: true,
      width: 150,
      ...getColumnSearchProps('title'),
      render: (_, record) => (
        <Space>
          <Dropdown overlay={menuLoja(record)}>
            <a
              href="#"
              className="ant-dropdown-link"
              onClick={e => e.preventDefault()}
            >
              {record.title} <DownOutlined />
            </a>
          </Dropdown>
        </Space>
      ),
    },
    {
      key: 'slug',
      title: 'Slug',
      dataIndex: 'slug',
      itemName: 'slug',
      editable: true,
      width: 120,
      render: (_, record) => (
        <>
          {record.slug}{' '}
          <a
            href={`https://www.daazcavernas.com/cupom-desconto/${record.slug}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkOutlined />
          </a>
        </>
      ),
    },
    {
      key: 'platform',
      title: 'Plataforma',
      dataIndex: 'plataform_stores',
      itemName: 'plataformsStore',
      width: 180,
      render: (plataform_stores, record) => {
        let tagValue
        let tagColor
        if (plataform_stores.length > 0) {
          //          console.log("plataform_stores: ",plataform_stores)
          const hasActive = plataform_stores.find(
            item => item.status === 'Ativo',
          )
          if (hasActive) {
            tagValue = `${hasActive.plataform.name}`
            tagColor = colorHash.hex(`${hasActive.plataform.name}`)
          } else {
            tagValue = 'Plataformas inativas'
            tagColor = 'default'
          }
        } else {
          tagValue = 'Sem plataforma'
          tagColor = 'default'
        }
        return (
          <div key={record.id} style={{ display: 'flex' }}>
            <Dropdown overlay={menuPlataforma(plataform_stores, record)}>
              <a
                href="#"
                className="ant-dropdown-link"
                onClick={e => e.preventDefault()}
              >
                <Tag color={tagColor}>
                  {tagValue} <DownOutlined />
                </Tag>
              </a>
            </Dropdown>
          </div>
        )
      },
    },
    {
      key: 'cpa',
      title: 'CPA | CPS',
      dataIndex: 'plataform_stores',
      itemName: 'cpa',
      width: 95,
      render: (plataform_stores, record) => {
        const maxCpa = maxPaymentValue(plataform_stores, 'cpa')
        return plataform_stores.length > 0
          ? plataform_stores.map(
              item =>
                item.status === 'Ativo' && (
                  <>
                    <Tag key={record.id} style={{ margin: '5px' }}>
                      {item.cpa}%
                    </Tag>
                    {maxCpa > item.cpa && (
                      <Badge key={record.id} status="warning" />
                    )}
                  </>
                ),
            )
          : ''
      },
    },
    {
      key: 'cpl',
      title: 'CPL',
      dataIndex: 'plataform_stores',
      availablePlataforms: plataforms,
      itemName: 'cpl',
      width: 80,
      render: (plataform_stores, record) => {
        const maxCpl = maxPaymentValue(plataform_stores, 'cpl')
        return (
          <>
            {plataform_stores.length > 0
              ? plataform_stores.map(
                  item =>
                    item.status === 'Ativo' && (
                      <>
                        <Tag key={record.id} style={{ margin: '5px' }}>
                          {item.cpl}
                        </Tag>
                        {maxCpl > parseCpl(item.cpl) && (
                          <Badge key={record.id} status="warning" />
                        )}
                      </>
                    ),
                )
              : ''}
          </>
        )
      },
    },
    {
      key: 'categoria',
      title: 'Categoria',
      width: 200,
      itemName: 'categoriesStore',
      editable: true,
      render: (_, record) => <span>Ver categorias da loja</span>,
    },
    {
      key: 'url',
      title: 'Url Home',
      dataIndex: 'url',
      itemName: 'url',
      editable: true,
      width: 350,
    },
    {
      key: 'updated_at',
      title: 'Data de Atualização',
      dataIndex: 'updated_at',
      width: 190,
      render: date => {
        return dayjs(date).format('DD/MM/YYYY HH:mm')
      },
    },
    {
      key: 'action',
      title: 'Ação',
      fixed: 'right',
      width: 80,
      render: (_, record) => {
        const editable = isEditing(record)
        return editable ? (
          <Space>
            <a
              href="#/"
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              <CheckCircleOutlined style={{ fontSize: 18 }} />
            </a>
            <Divider type="vertical" />
            <Popconfirm
              title="Tem certeza que deseja cancelar?"
              onConfirm={cancel}
            >
              <CloseCircleOutlined style={{ fontSize: 18 }} />
            </Popconfirm>
          </Space>
        ) : (
          <Space>
            <a
              href="#/"
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
            >
              <EditOutlined style={{ fontSize: 18 }} />
            </a>
            <Divider type="vertical" />
            <Popconfirm
              title="Tem certeza que deseja excluir esta loja?"
              onConfirm={() => handleDelete(record.id)}
            >
              <DeleteOutlined style={{ fontSize: 18 }} />
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: record => ({
        record,
        key: record.id,
        inputType: col.dataIndex,
        dataIndex: col.dataIndex,
        itemName: col.itemName,
        editing: isEditing(record),
        availablePlataforms: plataforms,
      }),
    }
  })
  return (
    <>
      <NewStoreButton />
      <EmptyStoresButton />
      <PausedStoresButton />
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          scroll={{ x: 1500, y: '70vh' }}
          loading={loading}
          rowClassName="editable-row"
          pagination={{
            onChange: onChangePagination,
            position: ['topRight', 'bottomRight'],
            pageSize: data.length > 10 ? data.length : 10,
            showSizeChanger: false,
            total: totalStores,
          }}
        />
      </Form>
    </>
  )
}

export default EditableTable
