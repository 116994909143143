import React from 'react'
import { withRouter } from 'react-router'
import { PageHeader } from 'antd'
import styled from 'styled-components'
import TableStores from '../../components/TableStores'

const Content = styled.main`
  position: relative;
  margin: 24px;
  transition: all 0.2s;
`
const Stores = () => (
  <Content>
    <PageHeader
      ghost={false}
      title="Tabela de Lojas"
      subTitle="Lojas existentes no sistema"
    />
    <TableStores></TableStores>
  </Content>
)

export default withRouter(Stores)
