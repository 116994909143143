import React from 'react'
import { Tooltip } from 'antd'
import SerpPreview from 'react-serp-preview'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import styled from 'styled-components'
import { InfoCircleOutlined } from '@ant-design/icons'

const mesEAno = dayjs().locale('pt-br').format('MMMM YYYY')

const Container = styled.div`
  padding: 16px;
  width: 100%;
  max-width: 576px;
  border: 1px solid #dfe3e8;
  border-radius: 8px;
  > div {
    width: 100%;
    max-width: 576px;
  }
`
/*
Title: 50-60
https://ahrefs.com/blog/title-tag-seo/

Description: 155–170
https://ahrefs.com/blog/find-featured-snippets/
*/

export default function MetaPreview({ store, title, description, slug }) {
  const defaultTitle = `Cupom de Desconto ${store} R$50 OFF - ${mesEAno}`
  const defaultDescription = `Cupom ${store} com até R$50 OFF de desconto, ✅ atualizados em Outubro 2020 ✅ além da coleção de descontos e cupons exclusivos DaazCavernas.`

  return (
    <>
      <Container>
        <Tooltip title="Visualização ilustrativa, valores e disposição nos buscadores podem variar">
          <InfoCircleOutlined />
        </Tooltip>
        <SerpPreview
          title={title ? `${title}` : `${defaultTitle}`}
          metaDescription={
            description ? `${description}` : `${defaultDescription}`
          }
          url={`https://www.daazcavernas.com/cupom-desconto/${slug}`}
        />
      </Container>
    </>
  )
}
