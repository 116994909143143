import React, { useState } from 'react'
import { Row, Col, Form, Input, Button, Alert } from 'antd'
import Logo from '../../assets/images/daazcavernas-logo-default.svg'
import { withRouter } from 'react-router'
import api from '../../services/api'
import { login } from '../../services/auth'
import styled from 'styled-components'
import bgImg from '../../assets/images/bg-daaz.svg'
import { Redirect } from 'react-router-dom'

const Container = styled(Row)`
  align-content: center;
  height: 100vh;
  background: url(${bgImg}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`

const Text = styled.p`
  padding: 15px 0;
`

const Login = () => {
  const [error, setError] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const onFinish = async values => {
    const { email, password } = values
    setSubmitting(true)
    try {
      const response = await api.post('sign_in', { email, password })
      const uid = await response.headers['uid']
      const client = await response.headers['client']
      const token = await response.headers['access-token']
      setSubmitting(false)
      login({ uid, client, token })
      setRedirect(true)
    } catch (error) {
      setSubmitting(false)
      setError(true)
      console.log(error)
    }
  }

  const onFinishFailed = errorInfo => {
    setError(true)
  }

  const onCloseAlert = () => setError(false)

  if (redirect) {
    return <Redirect to={{ pathname: '/' }} />
  }

  return (
    <Container type="flex" justify="center">
      <Col>
        <div>
          <img src={Logo} alt="Logo DaazCavernas" />
          <Text>DaazCavernas, os melhores descontos estão aqui</Text>
        </div>
        {error && (
          <Alert
            message="E-mail ou senha incorreto"
            type="warning"
            showIcon
            closable
            onClose={onCloseAlert}
          />
        )}
        <Form
          name="Login"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="E-mail"
            name="email"
            rules={[
              { required: true, type: 'email', message: 'Informe seu e-mail.' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Senha"
            name="password"
            rules={[{ required: true, message: 'Informe sua senha.' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" loading={submitting} block htmlType="submit">
              Acessar painel
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Container>
  )
}

export default withRouter(Login)
