import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import App from './App'

Sentry.init({
  dsn:
    process.env.NODE_ENV === 'production' &&
    'https://999880c01df549009cdd1019baf2d3d3@o406245.ingest.sentry.io/5307454',
})

ReactDOM.render(<App />, document.getElementById('root'))
