import React, { useEffect, useState, useCallback } from 'react'
import { withRouter, useHistory, useParams } from 'react-router'
import {
  PageHeader,
  Spin,
  Table,
  Tag,
  Popconfirm,
  Space,
  message,
  Form,
  Badge,
  Input,
  Switch,
  Select,
} from 'antd'

import styled from 'styled-components'
import dayjs from 'dayjs'

import { fetchData, deleteData, putData } from '../../../utils/request'

const { Option } = Select

const Content = styled.main`
  position: relative;
  margin: 24px;
  transition: all 0.2s;
`

const Loader = styled.div`
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
`

async function fetchOffersFromServer(storeSlug) {
  const res = await fetchData(`/stores/${storeSlug}/offers?limit=999`)
  return res
}

const EditableCell = ({
  editing,
  dataIndex,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = () => {
    if (editing) {
      switch (dataIndex) {
        case 'title':
          return (
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
              }}
              rules={[
                {
                  required: isRequired,
                  message: `Preencha este campo!`,
                },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          )
        case 'type_id':
          return (
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
              }}
              rules={[
                {
                  required: isRequired,
                  message: `Preencha este campo!`,
                },
              ]}
            >
              <Select defaultValue="lucy" style={{ width: 110 }}>
                <Option value={1}>Cupom</Option>
                <Option value={2}>Promoção</Option>
              </Select>
            </Form.Item>
          )
        case 'description':
          return (
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
              }}
              rules={[
                {
                  required: isRequired,
                  message: `Preencha este campo!`,
                },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          )
        case 'status':
          const valueStatus = record.status
          return (
            <Form.Item
              name="status"
              style={{
                margin: 0,
                textAlign: 'center',
              }}
              key={record.id}
              valuePropName={'checked'}
            >
              <Switch defaultChecked={valueStatus} />
            </Form.Item>
          )
        case 'exclusive':
          const valueField = record.exclusive
          return (
            <Form.Item
              name="exclusive"
              style={{
                margin: 0,
                textAlign: 'center',
              }}
              key={record.id}
              valuePropName={'checked'}
            >
              <Switch defaultChecked={valueField} />
            </Form.Item>
          )
        default:
          return (
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
              }}
              rules={[
                {
                  required: isRequired,
                  message: `Preencha este campo!`,
                },
              ]}
            >
              <Input />
            </Form.Item>
          )
      }
    }
  }
  const isRequired = dataIndex === 'title' ? true : false
  return <td {...restProps}>{editing ? inputNode() : children}</td>
}

function Offers({ fetchOffers = fetchOffersFromServer }) {
  const [form] = Form.useForm()
  const [editingKey, setEditingKey] = useState('')
  const [offers, setOffers] = useState(null)
  const [storeName] = useState(() => localStorage.getItem('viewing_store'))
  let history = useHistory()
  let { slug } = useParams()

  useEffect(
    function fetchOffersOnStart() {
      fetchOffers(slug).then(function updateStateWithStore(data) {
        setOffers(data)
      })
    },
    [fetchOffers, slug],
  )

  function handleBack() {
    history.goBack()
    localStorage.removeItem('viewing_store')
  }

  const isEditing = record => record.id === editingKey

  const save = async (key, storeId) => {
    try {
      const row = await form.validateFields()

      const formatData = {
        offer: {
          ...row,
        },
      }

      const response = await putData(
        `/stores/${storeId}/offers`,
        formatData,
        key,
      )

      if (response.id) {
        message.success('Oferta atualizada')
        const newData = [...offers]
        const index = newData.findIndex(item => key === item.id)
        if (index > -1) {
          const item = response
          newData.splice(index, 1, { ...item, ...row })
          setOffers(newData)
          setEditingKey('')
        } else {
          newData.push(row)
          setOffers(newData)
          setEditingKey('')
        }
      } else {
        message.error('Erro ao atualizar')
      }
    } catch (errInfo) {
      console.log('Error:', errInfo)
    }
  }

  const edit = record => {
    form.setFieldsValue({
      title: '',
      ...record,
    })
    setEditingKey(record.id)
  }

  const cancel = () => {
    setEditingKey('')
  }

  const handleDelete = useCallback(
    (id, storeId) => {
      const newData = offers.filter(item => item.id !== id)
      setOffers(newData)
      deleteData(`/stores/${storeId}/offers`, id)
      message.success('Excluido com sucesso')
    },
    [offers],
  )

  const columns = [
    {
      title: 'Tipo',
      dataIndex: 'type_id',
      key: 'type_id',
      editable: true,
      fixed: 'left',
      width: 120,
      render: (type_id, record) => {
        const isExpired = dayjs().format() > dayjs(record.expiration).format()
        if (isExpired) {
          return (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Tag color="red">Expirado</Tag>
            </div>
          )
        } else {
          return (
            <div style={{ width: '100%', textAlign: 'center' }}>
              {Number(type_id) === 1 ? (
                <Tag color="green">Cupom</Tag>
              ) : (
                <Tag color="blue">Desconto</Tag>
              )}
            </div>
          )
        }
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 80,
      editable: true,
      render: (status, _) => (
        <div style={{ textAlign: 'center' }}>
          {status ? <Badge status="success" /> : <Badge status="error" />}
        </div>
      ),
    },
    {
      title: 'Exclusivo',
      dataIndex: 'exclusive',
      width: 100,
      editable: true,
      render: (status, _) => (
        <div style={{ textAlign: 'center' }}>
          {status ? <Badge status="success" /> : <Badge status="error" />}
        </div>
      ),
    },
    {
      title: 'Desconto',
      dataIndex: 'discount',
      key: 'discount',
      editable: true,
      width: 130,
    },
    {
      title: 'Código',
      dataIndex: 'code',
      editable: true,
      width: 130,
    },
    {
      title: 'Data de início',
      dataIndex: 'start_date',
      editable: true,
      width: 200,
      render: date => {
        return dayjs(date).add(3, 'hours').format('DD/MM/YYYY HH:mm')
      },
    },
    {
      title: 'Data de Expiração',
      dataIndex: 'expiration',
      editable: true,
      width: 200,
      render: date => {
        return dayjs(date).add(3, 'hours').format('DD/MM/YYYY HH:mm')
      },
    },
    {
      title: 'Titulo',
      dataIndex: 'title',
      key: 'title',
      width: 220,
      editable: true,
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
      editable: true,
      width: 220,
    },
    {
      title: 'URL Oferta (PPC)',
      dataIndex: 'ppc',
      editable: true,
      width: 250,
    },
    {
      title: 'URL Base',
      dataIndex: 'url',
      editable: true,
      width: 250,
    },
    {
      title: 'Data de Criação',
      dataIndex: 'created_at',
      width: 200,
      render: date => {
        return dayjs(date).format('DD/MM/YYYY HH:mm')
      },
    },
    {
      title: 'Data de Atualização',
      dataIndex: 'updated_at',
      width: 200,
      render: date => {
        return dayjs(date).format('DD/MM/YYYY HH:mm')
      },
    },
    {
      title: 'Ação',
      fixed: 'right',
      width: 130,
      render: (_, record) => {
        const editable = isEditing(record)
        const storeId = record.store_id
        return editable ? (
          <Space>
            <a
              href="#/"
              onClick={() => save(record.id, storeId)}
              style={{
                marginRight: 8,
              }}
            >
              Salvar
            </a>
            <Popconfirm
              title="Tem certeza que deseja cancelar?"
              onConfirm={cancel}
            >
              <a href="#/">Cancelar</a>
            </Popconfirm>
          </Space>
        ) : (
          <Space>
            <a
              href="#/"
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
            >
              Editar
            </a>
            <Popconfirm
              title="Tem certeza que deseja excluir esta oferta?"
              onConfirm={() => handleDelete(record.id, record.store_id)}
            >
              <a href="#/">Excluir</a>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === 'status' ? 'radio' : 'text',
        dataIndex: col.dataIndex,
        name: col.name,
        editing: isEditing(record),
      }),
    }
  })

  return !offers ? (
    <Loader>
      <Spin></Spin>
    </Loader>
  ) : (
    <Content>
      <PageHeader
        ghost={false}
        onBack={handleBack}
        title={`Ofertas da loja ${storeName}`}
      />

      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          scroll={{ x: 1500, y: '70vh' }}
          dataSource={offers}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            position: ['bottomRight'],
            pageSize: offers ? offers.length : '',
            showSizeChanger: false,
          }}
        />
      </Form>
    </Content>
  )
}

export default withRouter(Offers)
