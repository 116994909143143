import React from 'react'
import { Layout, Menu } from 'antd'
import { Link, useRouteMatch } from 'react-router-dom'
import LogoImage from '../../assets/images/daazcavernas-logo-white.svg'
import { LogoutOutlined } from '@ant-design/icons'
import { logout } from '../../services/auth'

import styled from 'styled-components'
const { Header } = Layout

const Logo = styled.img`
  width: 150px;
  height: 31px;
  margin: 16px 24px 16px 0;
  float: left;
`

const HeaderS = styled(Header)`
  display: flex;
  justify-content: space-between;
`

export default function HeaderComponent() {
  const { path } = useRouteMatch()
  return (
    <HeaderS>
      <div style={{ flexGrow: '1' }}>
        <Logo src={LogoImage} width="150" alt="" srcset="" />
        <Menu theme="dark" mode="horizontal" selectedKeys={[path]}>
          <Menu.Item key="/">
            <Link to="/">Inicio</Link>
          </Menu.Item>
          <Menu.Item key="/tipos">
            <Link to="/tipos">Tipos</Link>
          </Menu.Item>
          <Menu.Item key="/tags">
            <Link to="/tags">Tags</Link>
          </Menu.Item>
          <Menu.Item key="/plataformas">
            <Link to="/plataformas">Plataformas</Link>
          </Menu.Item>
          <Menu.Item key="/lojas">
            <Link to="/lojas">Lojas</Link>
          </Menu.Item>
          <Menu.Item key="/categorias">
            <Link to="/categorias">Categorias</Link>
          </Menu.Item>
          <Menu.Item key="/ofertas">
            <Link to="/ofertas">Adicionar Ofertas</Link>
          </Menu.Item>
        </Menu>
      </div>
      <div>
        <Menu theme="dark" mode="horizontal">
          <Menu.Item key="8" icon={<LogoutOutlined />} onClick={logout}>
            Sair
          </Menu.Item>
        </Menu>
      </div>
    </HeaderS>
  )
}
