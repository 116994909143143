import React, { useState, useCallback } from 'react'
import { Modal, Popconfirm, Button, Select, message } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import { fetchData, deleteData, postData } from '../../utils/request'

import EditPlataformForm from './EditPlataformForm'

const { Option } = Select

async function fetchPlataformsFromServer() {
  const res = await fetchData('/plataforms')
  return res
}

const Plataform = styled.div`
  margin-top: 15px;
`

export default function EditPlataformExtraData({
  plataforms,
  storeId,
  storeName,
  fetchPlataforms = fetchPlataformsFromServer,
}) {
  const [visible, setVisible] = useState(false)
  const [loadingOptions, setLoadingOptions] = useState(false)
  const [selectOptions, setSelectOptions] = useState([])
  const [selected, setSelected] = useState({})
  const [storePlataforms, setStorePlataforms] = useState(plataforms)

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const handleDeletePlataform = async plataformId => {
    try {
      await deleteData(`/stores/${storeId}/plataform_stores`, plataformId)
      const removeDeleted = storePlataforms.filter(
        plataform => plataform.id !== plataformId,
      )
      message.success('Plataforma desvinculada')

      setStorePlataforms(removeDeleted)
    } catch (error) {
      message.error('Erro ao desvincular plataforma')
    }
  }

  const handleChangeSelect = value => {
    setSelected(value)
  }

  const handleAddPlataform = async () => {
    const getPlataformSelected = selectOptions.filter(
      plataform => plataform.id === selected,
    )

    try {
      const data = {
        plataform_store: {
          plataform_id: selected,
        },
      }

      const response = await postData(
        `/stores/${storeId}/plataform_stores`,
        data,
      )
      const virtualPlataform = {
        ...response,
        plataform: getPlataformSelected[0],
      }
      const updatedPlataformList = [...storePlataforms, virtualPlataform]

      setStorePlataforms(updatedPlataformList)
    } catch (error) {
      message.error('Erro ao adicionar plataforma')
    }
  }

  const handleFocusSelect = useCallback(async () => {
    setLoadingOptions(true)
    await fetchPlataforms().then(function updateStateWithPlataforms(data) {
      const filterActives = data.filter(
        ({ id: id1 }) =>
          !plataforms.some(({ plataform_id: id2 }) => id2 === id1),
      )

      const sortResult = filterActives.sort((a, b) =>
        a.name.localeCompare(b.name),
      )

      setSelectOptions(sortResult)
    })
    setLoadingOptions(false)
  }, [fetchPlataforms, plataforms])

  return (
    <>
      <a href="#/" onClick={showModal}>
        Plataformas
      </a>
      <Modal
        title={`Plataformas da loja ${storeName}`}
        visible={visible}
        onCancel={handleCancel}
        footer={false}
      >
        <Select
          showSearch
          placeholder="Selecione uma plataforma"
          style={{ width: 350 }}
          onChange={handleChangeSelect}
          onFocus={handleFocusSelect}
          loading={loadingOptions}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {selectOptions.map(plataform => (
            <Option value={plataform.id}>{plataform.name}</Option>
          ))}
        </Select>
        <Button onClick={handleAddPlataform}>Adicionar</Button>
        {storePlataforms.map(storePlataform => (
          <Plataform key={storePlataform.id}>
            <h2>
              {storePlataform.plataform.name}{' '}
              <Popconfirm
                title="Deseja desvincular essa plataforma da loja?"
                onConfirm={() => handleDeletePlataform(storePlataform.id)}
                okText="Sim"
                cancelText="Não"
              >
                <DeleteOutlined style={{ fontSize: 14, color: '#CF1322' }} />
              </Popconfirm>
            </h2>
            <EditPlataformForm
              storeId={storeId}
              plataform={storePlataform}
            ></EditPlataformForm>
          </Plataform>
        ))}
      </Modal>
    </>
  )
}
