import React, { useCallback } from 'react'
import { Button, message } from 'antd'

import api from '../../services/api'

const Exporter = ({ type }) => {
  // const { updateToken, token, client, uid } = useAuth()

  const handleClick = useCallback(() => {
    api({
      url: `/exporter?${type}=true`,
      method: 'GET',
      responseType: 'blob'
    })
      .then((response) => {
        console.log(response)
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `relatorio-${type}.csv`)
        document.body.appendChild(link)
        link.click()
      })
      .catch((err) => {
        message.error('Erro ao gerar relatório')
      })
  }, [type])

  return (
    <Button type="link" onClick={handleClick}>
      Exportar usuários em CSV
    </Button>
  )
}

export default Exporter
