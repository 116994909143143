import React, { useRef, useState, useEffect } from 'react'
import { withRouter, useHistory, useParams } from 'react-router'
import {
  Row,
  Col,
  Tooltip,
  PageHeader,
  Spin,
  Card,
  Typography,
  Upload,
  Divider,
  Tag,
  Popconfirm,
  message,
  Button
} from 'antd'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { InfoCircleOutlined, LinkOutlined, PlusOutlined, UploadOutlined  } from '@ant-design/icons'
import styled from 'styled-components'
import { useQuery, useMutation } from 'react-query'
import { Formik } from 'formik'
import { Form, FormItem, Input, SubmitButton } from 'formik-antd'
import { Persist } from 'formik-persist'

import MarkdownIt from 'markdown-it'
import MdEditor from 'react-markdown-editor-lite'
import dayjs from 'dayjs'

import baseApi, {apiUrl, apiShop} from '../../../services/api'

// import style manually
import 'react-markdown-editor-lite/lib/index.css'
import MetaPreview from '../../../components/MetaPreview'
import Counter from '../../../components/CharacterCounter'
import Questions from '../../../components/Questions'
import defaultContent from './defaultContent'
import Categories from './Categories'

const { Title, Text } = Typography

const getActivePlatform = platforms => {
  let activePlatform;
  try{
    activePlatform = platforms.filter(p => p.status === 1)[0]
  }catch(e){
    return null;
  }

  return activePlatform;

}


const mdParser = new MarkdownIt({
  html: true,
  linkify: true,
})

const getStore = async (key, slugParam) => {
  const { data } = await baseApi.get(`stores/${slugParam}`)
  return data
}

const putStore = async values => {
  const { data } = await baseApi.put(`stores/${values.slugParam}`, {
    store: { ...values.data },
  })
  return data
}

const putStoreLogo = async values => {
  console.log(values.data)

  const formDataMultiPart = new FormData()

  if (values.data) {
    formDataMultiPart.append(`store[image]`, values.data)
  }

  const { data } = await baseApi.put(`stores/${values.slugParam}`, formDataMultiPart)

  return data
}

const putStoreOpenGraph = async values => {
  const formDataMultiPart = new FormData()

  if (values.data) {
    formDataMultiPart.append(`store[open_graph]`, values.data)
  }

  const { data } = await baseApi.put(`stores/${values.slugParam}`, formDataMultiPart)

  return data
}

const Content = styled.main`
  position: relative;
  margin: 24px;
  transition: all 0.2s;
`

const Loader = styled.div`
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
`

const fetchStore = async id => {
  const { data } = await apiShop.get(`/detailssite/${id}`)
  return data[0]
}

const StoreContent = ({
  fetchStorePlatforms = fetchStore
}) => {
  const { slug: slugParam } = useParams()
  const history = useHistory()
  const mdEditor = useRef(null)
  const { data, isLoading, isError } = useQuery(['store', slugParam], getStore)

  const [imageUrl, setImageUrl] = useState('')
  const [imageOpenGraphUrl, setImageOpenGraphUrl] = useState('')
  const [file, setFile] = useState('')
  const [fileOpenGraph, setFileOpenGraph] = useState('')
  const [activePlatform, setPlatform] = useState(null)

  const beforeUpload = () => {
    return false
  }

  useEffect(() => {
    if(isLoading)
      return;
      
    if(activePlatform === null)
      fetchStorePlatforms(data.id)
        .then((p) => {
          
          setPlatform(getActivePlatform(p))
        });
  });

  function getBase64(img: any, callback: any) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const handleChange = (info: any, type = 'logo') => {
    const isJpgOrPng =
      info.file.type === 'image/jpeg' || info.file.type === 'image/png'

    if (!isJpgOrPng) {
      message.error('Você só pode enviar arquivos JPG/PNG!')
      return
    }

    const isLt2M = info.file.size / 1024 / 1024 < 2

    if (!isLt2M) {
      message.error('Imagem precisa ser menor que 2MB!')
      return
    }

    if(type === 'openGraph'){
      getBase64(info.file, (imageUrlInfo: any) => {
        setFileOpenGraph(info.file)
        setImageOpenGraphUrl(imageUrlInfo)
      })
    } else {
      getBase64(info.file, (imageUrlInfo: any) => {
        setFile(info.file)
        setImageUrl(imageUrlInfo)
      })
    }
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Enviar</div>
    </div>
  )

  const [updateStore] = useMutation(putStore)
  const [updateStoreLogo] = useMutation(putStoreLogo)
  const [updateStoreOpenGraph] = useMutation(putStoreOpenGraph)

  const handleSubmit = async values => {
    try {
      if(file) {
        await updateStoreLogo({ data: file, slugParam })
      }

      if(fileOpenGraph){
        await updateStoreOpenGraph({ data: fileOpenGraph, slugParam })
      }

      await updateStore({ data: values, slugParam })
      message.success('Loja atualizada com sucesso')
    } catch {
      message.error('Erro ao atualizar loja')
    }
  }

  const renderHTML = text => {
    return mdParser.render(text)
  }

  function handleBack() {
    history.goBack()
  }

  if (isLoading) {
    return (
      <Loader>
        <Spin></Spin>
      </Loader>
    )
  }

  if (isError) {
    return (
      <Content>
        <PageHeader
          ghost={false}
          onBack={handleBack}
          title={`Editando conteúdo`}
        />
        <Card>Erro ao carregar dados</Card>
      </Content>
    )
  }

  const {
    id,
    title,
    meta_tag_title,
    meta_tag_description,
    created_at,
    updated_at,
    url,
    status,
    slug,
    min_description,
    description,
    image_url,
    open_graph_url
  } = data

  const initialValues = {
    meta_tag_title: meta_tag_title ? meta_tag_title : '',
    meta_tag_description: meta_tag_description ? meta_tag_description : '',
    min_description: min_description ? min_description : '',
    description: description ? description : '',
    image_url: image_url ? image_url : '',
    open_graph_url: open_graph_url ? open_graph_url : '',
  }

  const tabRules = 
    <TabPanel key="regras">
      <Card>
        <Row>
          <Title level={3}>Regras da plataforma</Title>
        </Row>
        <Divider /> 
        {
          (activePlatform !== undefined && activePlatform !== null)
            ?
            <div dangerouslySetInnerHTML={{
              __html: activePlatform.description
            }}/> 
            : <Text type="secondary">Aparentemente não existem plataformas ativas para essa loja :/</Text>
        }
        
      </Card>
      
    </TabPanel> 
  

  return (
    <Content>
      <PageHeader
        ghost={false}
        onBack={handleBack}
        title={`${title} | Editando conteúdo`}
      />
      <Divider />
      <Tabs id="tbConteudo" defaultIndex={0}>
        <TabList>
          <Tab key="conteudo" label="Conteudo">
            Metas SEO
          </Tab>
          <Tab key="regras" label="Regras">
            Regras
          </Tab>
        </TabList>
        <TabPanel key="conteudo">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        //validationSchema={validationSchema}
        onSubmit={async values => {
          await handleSubmit(values)
        }}
      >
        {({ values, setFieldValue }) => (
          <Form layout="vertical">
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <Card>
                  <Row gutter={[16, 16]} justify="center" align="middle">
                    <Col span={24}>
                      <Upload
                        name="store-logo"
                        listType="picture-card"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                      >
                        {imageUrl || image_url ? (
                          <img
                            src={
                              imageUrl ||
                              `${apiUrl}${image_url}`
                            }
                            alt="Imagem da loja"
                            style={{ width: '100%' }}
                            />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                      <p>
                        <b>Loja</b>: {title}{' '}
                        <a
                          href={`https://www.daazcavernas.com/cupom-desconto/${slug}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LinkOutlined />
                        </a>
                      </p>
                      <p>
                        <b>Data de criação</b>:{' '}
                        {dayjs(created_at).format('DD/MM/YYYY HH:mm')}
                      </p>
                      <p>
                        <b>Última atualização</b>:{' '}
                        {dayjs(updated_at).format('DD/MM/YYYY HH:mm')}
                      </p>
                      <p>
                        <b>URL</b>: {url}
                      </p>
                      <p>
                        <b>Status</b>:{' '}
                        {status ? (
                          <Tag color="green">Ativa</Tag>
                        ) : (
                          <Tag color="green">Inativa</Tag>
                        )}
                      </p>
                      <p>
                        <b>Ofertas Ativas</b>: {data.total_active_offers}
                      </p>
                    </Col>
                    <Col span={24}>
                      <div style={{ display: 'flex' }}>
                        <Title level={4}>Introdução Loja</Title>
                        <Tooltip title="Use a tag: {divide} para separar o texto">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                      <FormItem name="min_description">
                        <Input.TextArea
                          name="min_description"
                          placeholder="Introdução Loja"
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  <SubmitButton block>Salvar loja</SubmitButton>
                </Card>

                <Card>
                  <Categories storeId={id} />
                </Card>                  
              </Col>
              <Col span={18}>
                <Card>
                  <Row>
                    <Title level={3}>Metas SEO</Title>
                    <Tooltip title="Tags: {meseano} {melhoroferta} {nomeloja}">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Row>
                  <Row gutter={[16, 16]} justify="center" align="middle">
                    <Col span={12}>
                      <FormItem
                        name="meta_tag_title"
                        label="Meta Title"
                        //validate={validateRequired}
                        >
                         <Input name="meta_tag_title" placeholder="Meta Title" />
                        </FormItem>
                        <FormItem
                          name="meta_tag_description"
                          label="Meta Description"
                          //validate={validateRequired}
                        >
                          <Input.TextArea
                            name="meta_tag_description"
                            placeholder="Meta Description"
                          />
                        </FormItem>
                        <Upload
                          name="store-graph"
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          onChange={(e) => handleChange(e, 'openGraph')}>
                          <Button icon={<UploadOutlined />}>Enviar Open Graph Image</Button>
                          {imageOpenGraphUrl || open_graph_url ? <img
                              src={
                                imageOpenGraphUrl ||
                                `${apiUrl}${open_graph_url}`
                              }
                              alt="Imagem open graph loja"
                              style={{ width: '80px', marginLeft:'8px' }}
                            /> : ''
                          }
                        </Upload>
                      </Col>
                      <Col span={12}>
                        <>
                          Caracteres Título:{' '}
                          <Counter
                            length={values.meta_tag_title.length}
                            min={55}
                            max={60}
                          />
                        </>
                        <>
                          Caracteres Descrição:{' '}
                          <Counter
                            length={values.meta_tag_description.length}
                            min={155}
                            max={170}
                          />
                        </>
                        <MetaPreview
                          store={title}
                          title={values.meta_tag_title}
                          description={values.meta_tag_description}
                          slug={slug}
                      />
                    </Col>
                  </Row>
                </Card>

                <Card>
                  <Row>
                    <Title level={3}>Descrição</Title>
                  </Row>
                  <Popconfirm
                    title="Isso substituirá o conteúdo atual, tem certeza?"
                    onConfirm={() =>
                      setFieldValue('description', defaultContent(title))
                    }
                    //onCancel={}
                    okText="Sim"
                    cancelText="Não"
                  >
                    <a href="/#">Importar descrição padrão</a>
                  </Popconfirm>
                  <Row gutter={[16, 16]} justify="center" align="middle">
                    <Col span={24}>
                      <MdEditor
                        ref={mdEditor}
                        value={values.description}
                        config={{
                          view: {
                            menu: true,
                            md: true,
                            html: true,
                            fullScreen: true,
                            hideMenu: true,
                          },
                          htmlClass: 'custom-html-style daaz-md',
                        }}
                        renderHTML={text => renderHTML(text)}
                        style={{ height: '50vh' }}
                        onChange={({ text }) =>
                          setFieldValue('description', text)
                        }
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Persist name={`content-store-${slug}`} />
          </Form>
        )}
      </Formik>
      <Row>
        <Col offset={6} span={18}>
            <Card>
              <Row>
                <Title level={3}>Perguntas</Title>
              </Row>
              <Row>
                <Questions storeId={data.id} storeTitle={data.title} />
              </Row>
            </Card>
          </Col> 
        </Row>
        </TabPanel>
        {tabRules}
      </Tabs>
      
      
    </Content>
  )
}

export default withRouter(StoreContent)
