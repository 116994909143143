import { useQuery } from 'react-query'
import api from '../services/api'

const getStores = async () => {
  const { data } = await api.get('stores')
  return data
}

export default function usePosts() {
  return useQuery('stores', getStores)
}
