import React, { useState, useEffect } from 'react'
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Tag,
  message,
  Radio,
} from 'antd'
import { fetchData, postData, deleteData, putData } from '../../utils/request'
import dayjs from 'dayjs'

async function fetchCategoriesFromServer() {
  const res = await fetchData('/categories')
  return res
}

const EditableCell = ({
  editing,
  dataIndex,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === 'radio' ? (
      <Radio.Group value={record.status}>
        <Radio value="1">Ativo</Radio>
        <Radio value="0">Inativo</Radio>
      </Radio.Group>
    ) : (
      <Input />
    )
  const isRequired = dataIndex === 'title' ? true : false
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: isRequired,
              message: `Preencha este campo!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

const EditableTable = ({ fetchCategories = fetchCategoriesFromServer }) => {
  const [form] = Form.useForm()
  const [data, setData] = useState([])
  const [editingKey, setEditingKey] = useState('')

  useEffect(
    function fetchCharactersOnStart() {
      fetchCategories().then(function updateStateWithPlataforms(data) {
        setData(data)
      })
    },
    [fetchCategories],
  )

  const isEditing = record => record.id === editingKey

  const edit = record => {
    form.setFieldsValue({
      title: '',
      ...record,
    })
    setEditingKey(record.id)
  }

  const cancelNewRow = () => {
    const filterNew = data.filter(item => item.id !== 0)
    setData(filterNew)
    setEditingKey('')
  }

  const cancel = () => {
    setEditingKey('')
  }

  const handleDelete = id => {
    const newData = data.filter(item => item.id !== id)
    setData(newData)
    deleteData('/categories', id)
    message.success('Excluido com sucesso')
  }

  const handleAdd = () => {
    const temporaryId = 0
    const newData = {
      id: temporaryId,
    }
    setData([newData, ...data])
    setEditingKey(temporaryId)
  }

  const save = async key => {
    try {
      const row = await form.validateFields()
      row.status = row.status === '1' ? 1 : 0

      const isANewRow = key === 0 ? true : false

      if (isANewRow) {
        const { title } = row
        const dataPost = { title }
        const response = await postData('/categories', dataPost)
        if (response.id) {
          const newData = [response, ...data]
          const filterNew = newData.filter(item => item.id !== 0)
          setData(filterNew)
          setEditingKey('')
          message.success('Categoria criada')
        } else {
          message.error('Erro ao criar')
        }
      } else {
        const formatData = {
          category: {
            ...row,
          },
        }
        const response = await putData('/categories', formatData, key)
        if (response.id) {
          message.success('Categoria atualizada')
          const newData = [...data]
          const index = newData.findIndex(item => key === item.id)
          if (index > -1) {
            const item = response
            newData.splice(index, 1, { ...item, ...row })
            setData(newData)
            setEditingKey('')
          } else {
            newData.push(row)
            setData(newData)
            setEditingKey('')
          }
        } else {
          message.error('Erro ao atualizar')
        }
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      rowKey: 'id',
      width: '5%',
    },
    {
      title: 'Título',
      dataIndex: 'title',
      width: '20%',
      editable: true,
    },
    {
      title: 'Meta Title',
      dataIndex: 'meta_title',
      editable: true,
    },
    {
      title: 'Meta Description',
      dataIndex: 'meta_description',
      editable: true,
      width: '20%',
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      editable: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '7%',
      editable: true,
      render: (status, _) =>
        Number(status) === 1 ? (
          <Tag color="green">Ativo</Tag>
        ) : (
          <Tag color="red">Inativo</Tag>
        ),
    },
    {
      title: 'Data de Criação',
      dataIndex: 'created_at',
      width: '10%',
      render: date => {
        return dayjs(date).format('DD/MM/YYYY HH:mm')
      },
    },
    {
      title: 'Data de Atualização',
      dataIndex: 'updated_at',
      width: '10%',
      render: date => {
        return dayjs(date).format('DD/MM/YYYY HH:mm')
      },
    },
    {
      title: 'Ação',
      fixed: 'right',
      width: 150,
      render: (_, record) => {
        const editable = isEditing(record)
        return editable || record.newRow ? (
          <span>
            <a
              href="#/"
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              Salvar
            </a>
            <Popconfirm
              title="Tem certeza que deseja cancelar?"
              onConfirm={record.id === 0 ? cancelNewRow : cancel}
            >
              <a href="#/">Cancelar</a>
            </Popconfirm>
          </span>
        ) : (
          <>
            <a
              href="#/"
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
            >
              Editar
            </a>
            <Popconfirm
              title="Tem certeza que deseja excluir esta categoria?"
              onConfirm={() => handleDelete(record.id)}
            >
              <Button type="link">Excluir</Button>
            </Popconfirm>
          </>
        )
      },
    },
  ]
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === 'status' ? 'radio' : 'text',
        dataIndex: col.dataIndex,
        name: col.name,
        editing: isEditing(record),
      }),
    }
  })
  return (
    <>
      <Button
        onClick={handleAdd}
        type="primary"
        disabled={editingKey !== ''}
        style={{
          marginBottom: 16,
          marginTop: 16,
        }}
      >
        Adicionar Categoria
      </Button>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          scroll={{ x: 1200 }}
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
            position: ['topRight', 'bottomRight'],
          }}
        />
      </Form>
    </>
  )
}

export default EditableTable
