import React from 'react'
import { Row, Col, Button, Divider, message } from 'antd'
import { useQuery, useMutation } from 'react-query'
import { Formik, FieldArray } from 'formik'
import { Form, FormItem, Input, SubmitButton } from 'formik-antd'
import api from '../../services/api'

const getQuestions = async (_, storeId) => {
  const { data } = await api.get(`stores/${storeId}/questions`)
  return data
}

const putQuestion = async values => {
  const { data } = await api.put(
    `stores/${values.storeId}/questions/${values.item.id}`,
    {
      question: { ...values.item },
    },
  )
  return data
}

const postQuestion = async values => {
  const { data } = await api.post(`stores/${values.storeId}/questions`, {
    question: { ...values.item },
  })
  return data
}

const deleteQuestion = async values => {
  const { data } = await api.delete(
    `stores/${values.storeId}/questions/${values.id}`,
  )
  return data
}

export default function Questions({ storeId, storeTitle }) {
  const { data, isLoading, isError } = useQuery(
    ['store', storeId],
    getQuestions,
  )

  const defaultQuestions = [
    {
      title: `❓ ${storeTitle} tem Cupom de Desconto?`,
      description: `Sim, a ${storeTitle} tem cupom de desconto, temos 9 cupons de desconto que estão ativos neste momento.`,
    },
    {
      title: `💰 Como conseguir Cupom de Desconto ${storeTitle}?`,
      description: `Para conseguir nosso Cupom de desconto ${storeTitle}, acesse o DaazCavernas, pegue o cupom desejado e vá para ${storeTitle}, faça sua compra normalmente. Cole nosso Código de Desconto no campo: "cupom, cupom de desconto, vale presente, código, código promocional". Pronto, seu desconto será aplicado antes de você finalizar a compra.`,
    },
    {
      title: `🤑 Como Economizar na loja ${storeTitle}?`,
      description: `Economize por meio do DaazCavernas, utilize nossos cupons de desconto para economizar na ${storeTitle}. Escolha a melhor oferta e aplique o cupom em sua compra. Acompanhe também a ${storeTitle} nas redes sociais e fique ligado em datas especiais, como: Dia das mães, Aniversário ${storeTitle}, Dia dos pais, Natal, Dia das crianças, Black Friday, Cyber Monday, Páscoa, Carnaval, Volta às Aulas, Dia do Cliente, Dia do consumidor...`,
    },
    {
      title: `🤔 Como conseguir Descontos na ${storeTitle}?`,
      description: `Diversos Descontos são disponibilizados diariamente no DaazCavernas. Seja membro de nossa comunidade, acesse nosso portal, redes sociais ou assine nossa newsletter.`,
    },
  ]

  const initialValues = data
    ? { questions: data }
    : {
        questions: [],
      }

  const [updateQuestion] = useMutation(putQuestion)
  const [createQuestion] = useMutation(postQuestion)
  const [removeQuestion] = useMutation(deleteQuestion)

  const handleSubmit = async values => {
    values.questions.forEach(async function (item) {
      if (item.id) {
        try {
          await updateQuestion({ item, storeId })
          message.success('Pergunta atualizada com sucesso')
        } catch {
          message.error('Erro ao atualizar Pergunta')
        }
      } else {
        try {
          await createQuestion({ item, storeId })
          message.success('Pergunta criada com sucesso')
        } catch {
          message.error('Erro ao criar Pergunta')
        }
      }
    })
  }

  const handleDelete = async id => {
    try {
      await removeQuestion({ id, storeId })
      message.success('Pergunta removida com sucesso')
    } catch {
      message.error('Erro ao remover loja')
    }
  }

  if (isError) {
    return 'Erro ao carregar perguntas'
  }

  if (isLoading) {
    return 'Carregando'
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async values => {
        await handleSubmit(values)
      }}
    >
      {({ values }) => (
        <Form>
          <FieldArray name="questions">
            {({ insert, remove, push }) => (
              <>
                {values.questions.length > 0 &&
                  values.questions.map((question, index) => (
                    <Row key={index} justify="end">
                      <Col span={24}>
                        <FormItem
                          name={`${index}.title`}
                          label="Pergunta"
                          htmlFor={`${index}.title`}
                        >
                          <Input
                            name={`questions.${index}.title`}
                            placeholder="Pergunta"
                            required
                          />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          name={`questions.${index}.description`}
                          label="Resposta"
                          htmlFor={`questions.${index}.description`}
                        >
                          <Input.TextArea
                            name={`questions.${index}.description`}
                            placeholder="Resposta"
                            required
                          />
                        </FormItem>
                      </Col>
                      <Col>
                        <Button
                          danger
                          style={{ marginBottom: 16 }}
                          onClick={() => {
                            handleDelete(question.id)
                            remove(index)
                          }}
                        >
                          Remover pergunta
                        </Button>
                      </Col>
                    </Row>
                  ))}
                <Button
                  type="secondary"
                  onClick={() => push({ title: '', description: '' })}
                >
                  + Pergunta
                </Button>
                <Divider type="vertical" />
                <Button
                  onClick={() =>
                    defaultQuestions.map(question => push(question))
                  }
                  type="link"
                >
                  Importar perguntas padrões
                </Button>
              </>
            )}
          </FieldArray>
          <Divider type="vertical" />
          <SubmitButton>Salvar perguntas</SubmitButton>
        </Form>
      )}
    </Formik>
  )
}
