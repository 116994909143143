import React from 'react'
import { withRouter } from 'react-router'
import { PageHeader, Row, Col, Card } from 'antd'
import styled from 'styled-components'
import BulkAddOffers from '../../components/BulkAddOffers'

const Content = styled.main`
  position: relative;
  margin: 24px;
  transition: all 0.2s;
`
function Offers() {
  return (
    <Content>
      <PageHeader
        ghost={false}
        title="Tabela de Ofertas"
        subTitle="Ofertas existentes no sistema"
      />
      <Row gutter={[24, 16]}>
        <Col span={24}>
          <Card>
            <BulkAddOffers />
          </Card>
        </Col>
      </Row>
    </Content>
  )
}

export default withRouter(Offers)
