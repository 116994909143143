import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { apiUrl } from '../../services/api'
import dayjs from 'dayjs'
import styled from 'styled-components'
import {
  Button,
  Divider,
  Spin,
  message,
  notification,
  Popconfirm,
  List,
  Card,
} from 'antd'
import ReactDataGrid from 'react-data-grid'
import { Editors } from 'react-data-grid-addons'
import { CSVLink } from 'react-csv'

import { postData } from '../../utils/request'
const { DropDownEditor } = Editors

const Container = styled.div`
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
`

async function fetchStoresFromServer() {
  const res = await axios.get(`${apiUrl}/sitemap`)
  return res.data
}

function formatOfferData(data) {
  const {
    title,
    store,
    description,
    expiration,
    start_date,
    ppc,
    ppv,
    code,
    discount,
    url,
    type,
  } = data

  const finalObject = {
    offer: {
      title,
      ...(description && { description }),
      ...(expiration && { expiration }),
      ...(start_date && { start_date }),
      ppc,
      ...(ppv && { ppv }),
      ...(code && { code }),
      ...(discount && { discount }),
      ...(url && { url }),
      type_id: type === 'Cupom' ? 1 : 2,
    },
  }
  const separateStoreNameFromSlug = store.split('#')
  const storeSlug = separateStoreNameFromSlug[1].replace(/\s/g, '')

  return [finalObject, storeSlug]
}

const csvHeaders = [
  { label: 'Titulo', key: 'title' },
  { label: 'Loja', key: 'store' },
  { label: 'Tipo', key: 'type' },
  { label: 'Desconto', key: 'discount' },
  { label: 'Código', key: 'code' },
  { label: 'Data de Início', key: 'start_date' },
  { label: 'Data de Expiração', key: 'expiration' },
  { label: 'Descrição', key: 'description' },
  { label: 'URL Oferta(PPC)', key: 'ppc' },
  { label: 'URL Base', key: 'url' },
]

export default function BulkAddOffers({ fetchStores = fetchStoresFromServer }) {
  const [rows, setRows] = useState(() => {
    const storagedRows = localStorage.getItem('@daaz:offers')

    if (storagedRows) {
      return JSON.parse(storagedRows)
    }

    return []
  })

  const [stores, setStores] = useState([])
  const [loading, setLoading] = useState(false)
  const [failOffers, setFailOffers] = useState([])
  const [successOffers, setSuccessOffers] = useState([])
  const [blockButton, setBlockButton] = useState(false)

  useEffect(
    function fetchDataOnStart() {
      setLoading(true)
      fetchStores().then(function updateStateWithData(data) {
        const ordered = data.sort((a, b) => {
          return a.title.localeCompare(b.title)
        })

        const filterData = ordered.map(item => ({
          id: item.id,
          value: `${item.title} # ${item.slug}`,
        }))
        setStores(filterData)
        setLoading(false)
      })
    },
    [fetchStores],
  )

  function confirmCleanTable() {
    message.success('Tabela foi limpa')
    setBlockButton(false)
    setRows([])
    localStorage.removeItem('@daaz:offers')
  }

  useEffect(() => {
    localStorage.setItem('@daaz:offers', JSON.stringify(rows))
  }, [rows])

  const types = [
    { id: 'cupom', value: 'Cupom' },
    { id: 'promoção', value: 'Desconto' },
  ]
  const AllStoresField = <DropDownEditor options={stores} />
  const TypeField = <DropDownEditor options={types} />

  const defaultColumnProperties = {
    resizable: true,
    width: 180,
  }

  const columns = [
    { key: 'store', name: 'Loja', editor: AllStoresField, frozen: true },
    { key: 'type', name: 'Tipo', editor: TypeField },
    { key: 'discount', name: 'Desconto', editable: true },
    { key: 'code', name: 'Código', editable: true },
    { key: 'start_date', name: 'Data de Inicio', editable: true },
    { key: 'expiration', name: 'Data de Expiração', editable: true },
    { key: 'title', name: 'Titulo', editable: true },
    { key: 'description', name: 'Descrição', editable: true },
    { key: 'ppc', name: 'URL Oferta (PPC)', editable: true },
    { key: 'url', name: 'URL Base', editable: true },
  ].map(c => ({ ...c, ...defaultColumnProperties }))

  const onGridRowsUpdated = useCallback(
    ({ fromRow, toRow, updated }) => {
      const newRows = rows.slice()
      for (let i = fromRow; i <= toRow; i++) {
        newRows[i] = { ...newRows[i], ...updated }
      }
      setRows(newRows)
    },
    [rows],
  )

  const handleNewRow = () => {
    setRows([
      ...rows,
      { title: '' },
      { title: '' },
      { title: '' },
      { title: '' },
    ])
  }

  const handleSubmit = () => {
    const filterEmpty = rows.filter(item => item.title !== '')

    const validatedOffers = filterEmpty.map(item => {
      if (!item.title || !item.store || !item.ppc || !item.type) {
        return message.error('Campos titulo, loja, ppc e tipo são obrigatórios')
      } else {
        return item
      }
    })

    if (validatedOffers.includes(undefined)) {
      return message.error('Existe uma oferta com campos faltantes')
    }

    if (!validatedOffers.includes(undefined) && filterEmpty.length === 0) {
      return message.warn('Nenhuma oferta para ser cadastrada')
    }

    if (!validatedOffers.includes(undefined) && filterEmpty.length > 0) {
      setBlockButton(true)
      validatedOffers.map(async offer => {
        const [data, storeSlug] = await formatOfferData(offer)

        const response = await postData(`/stores/${storeSlug}/offers`, data)

        if (!response.id) {
          setFailOffers(prevState => [...prevState, data.offer])
        } else {
          notification['success']({
            message: `Oferta cadastrada`,
          })
          setSuccessOffers(prevState => [...prevState, data.offer])
        }
      })
    }
  }

  if (loading) {
    return (
      <Container>
        <Spin tip="Carregando dados..." />
      </Container>
    )
  }

  return (
    <>
      <Button onClick={handleNewRow}>Nova linha</Button>
      <Divider type="vertical"></Divider>
      <Button onClick={handleSubmit} disabled={blockButton} type="primary">
        Cadastrar Ofertas
      </Button>
      <Divider type="vertical"></Divider>
      <CSVLink
        filename={`${dayjs().format('DD-MM-YYYY-HH-mm')}-ofertas.csv`}
        data={rows}
        headers={csvHeaders}
      >
        Baixar CSV de Ofertas
      </CSVLink>
      <Divider type="vertical"></Divider>
      <Popconfirm
        title="Tem certeza que deseja limpar a tabela?"
        onConfirm={confirmCleanTable}
        okText="Sim"
        cancelText="Não"
      >
        <Button type="link">Limpar tabela</Button>
      </Popconfirm>
      <Divider />
      {failOffers && (
        <>
          {failOffers.map(offer => (
            <> Oferta - {offer} - Falhou no cadastro</>
          ))}
        </>
      )}
      <div style={{ width: '100%' }}>
        <ReactDataGrid
          columns={columns}
          rowGetter={i => rows[i]}
          rowsCount={rows.length}
          onGridRowsUpdated={onGridRowsUpdated}
          enableCellSelect={true}
        />
      </div>
      <Divider />
      <h2>Ofertas cadastradas</h2>
      <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={successOffers}
        renderItem={offer => (
          <List.Item>
            <Card title={`${offer.title} ✅`}>{offer.description}</Card>
          </List.Item>
        )}
      />
    </>
  )
}
