import React, { useContext, useState, useEffect, useRef } from 'react'
import { Table, Input, Button, Popconfirm, Form, message } from 'antd'
import { fetchData, postData, deleteData, putData } from '../../utils/request'
import dayjs from 'dayjs'

const EditableContext = React.createContext()

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef()
  const form = useContext(EditableContext)
  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    })
  }

  const save = async e => {
    try {
      const values = await form.validateFields()
      toggleEdit()
      handleSave({ ...record, ...values })
    } catch (errInfo) {
      message.error('Erro ao salvar')
      console.log('Save failed:', errInfo)
    }
  }

  let childNode = children

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} é obrigatório.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 10,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    )
  }

  return <td {...restProps}>{childNode}</td>
}

export default class EditableTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dataSource: [],
      loading: false,
      disableAddButton: false,
    }

    this.columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        rowKey: 'id',
        width: '3%',
      },
      {
        title: 'Nome',
        dataIndex: 'name',
        width: '30%',
        editable: true,
      },
      {
        title: 'Data de Criação',
        dataIndex: 'created_at',
        width: '8%',
        render: date => {
          return dayjs(date).format('DD/MM/YYYY HH:mm')
        },
      },
      {
        title: 'Data de Atualização',
        dataIndex: 'updated_at',
        width: '8%',
        render: date => {
          return dayjs(date).format('DD/MM/YYYY HH:mm')
        },
      },
      {
        title: 'Ação',
        dataIndex: 'action',
        width: '10%',
        render: (_, record) => (
          <>
            {this.state.dataSource.length >= 1 ? (
              <Popconfirm
                title="Tem certeza que deseja excluir este tipo?"
                onConfirm={() => this.handleDelete(record.id)}
              >
                <Button type="link">Excluir</Button>
              </Popconfirm>
            ) : null}
          </>
        ),
      },
    ]
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const response = await fetchData('/types')
    this.setState({ loading: false, dataSource: response })
  }

  handleDelete = async id => {
    if (id === 0) {
      const dataSource = [...this.state.dataSource]
      this.setState({
        dataSource: dataSource.filter(item => item.id !== id),
        disableAddButton: false,
      })
      return
    }

    const response = await deleteData('/types', id)
    if (response.status !== 204) return message.error('Erro ao excluir')

    const dataSource = [...this.state.dataSource]
    this.setState({
      dataSource: dataSource.filter(item => item.id !== id),
      disableAddButton: false,
    })

    message.success('Excluido com sucesso')
  }

  handleAdd = () => {
    const { dataSource } = this.state
    const newData = {
      id: 0,
      name: `Nome do tipo`,
      new: true,
    }
    this.setState({
      dataSource: [newData, ...dataSource],
      disableAddButton: true,
    })
  }

  handleSave = async row => {
    const name = row.name
    const { dataSource } = this.state
    const data = {
      type: {
        name,
      },
    }

    if (row.new) {
      const response = await postData('/types', data)
      const filterRow = dataSource.filter(item => item.id !== 0)
      const newData = [response, ...filterRow]
      this.setState({
        dataSource: newData,
        disableAddButton: false,
      })
      return
    }

    const response = await putData('/types', data, row.id)
    if (!response.id) return message.success('Erro ao atualizar')

    const newData = [...dataSource]
    const index = newData.findIndex(response => row.id === response.id)
    if (index > -1) {
      newData.splice(index, 1, { ...response })
    } else {
      newData.push(response)
    }

    this.setState({
      dataSource: newData,
      disableAddButton: false,
    })

    message.success('Atualizado com sucesso')
  }

  render() {
    const { dataSource, loading, disableAddButton } = this.state
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    }
    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col
      }

      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      }
    })
    return (
      <div>
        <Button
          onClick={this.handleAdd}
          type="primary"
          disabled={disableAddButton}
          style={{
            marginBottom: 16,
            marginTop: 16,
          }}
        >
          Adicionar Tipo
        </Button>
        <Table
          loading={loading}
          components={components}
          rowKey={record => record.id}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={dataSource}
          columns={columns}
        />
      </div>
    )
  }
}
