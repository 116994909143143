export const login = async ({ uid, client, token, name }) => {
  localStorage.setItem('token', token)
  localStorage.setItem('client', client)
  localStorage.setItem('uid', uid)
}

export const logout = () => {
  // to support logging out from all windows
  window.localStorage.setItem('logout', Date.now())
  window.location.href = '/'
  window.localStorage.removeItem('token')
  window.localStorage.removeItem('client')
  window.localStorage.removeItem('uid')
}

export const getToken = () => {
  const token = localStorage.getItem('token')
  return token
}

export const getUid = () => {
  const uid = localStorage.getItem('uid')
  return uid
}

export const getClient = () => {
  const client = localStorage.getItem('client')
  return client
}

export const setToken = token => {
  localStorage.setItem('token', token)
}

export const setClient = client => {
  localStorage.setItem('client', client)
}

export const isAuthenticated = () => {
  const token = getToken()
  if (!token) {
    return false
  }
  return true
}
