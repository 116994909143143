import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

// Layout
import { Layout } from 'antd'
import Header from '../components/Header'
import Footer from '../components/Footer'

import Login from '../pages/Login'
import Dashboard from '../pages/Dashboard'
import Types from '../pages/Types'
import Tags from '../pages/Tags'
import Plataforms from '../pages/Plataforms'

import Stores from '../pages/Stores'
import StoreContent from '../pages/Stores/Content'
import StoreOffers from '../pages/Stores/Offers'

import Categories from '../pages/Categories'
import Offers from '../pages/Offers'

import { isAuthenticated } from '../services/auth'

const { Content } = Layout

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Layout style={{ minHeight: '100vh' }}>
          <Header />
          <Content style={{ padding: '24px' }}>
            <Component {...props} />
          </Content>
          <Footer />
        </Layout>
      ) : (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      )
    }
  />
)

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/login" component={Login} />
      <PrivateRoute exact path="/" component={Dashboard} />
      <PrivateRoute exact path="/tipos" component={Types} />
      <PrivateRoute exact path="/tags" component={Tags} />
      <PrivateRoute exact path="/plataformas" component={Plataforms} />
      <PrivateRoute exact path="/lojas" component={Stores} />
      <PrivateRoute
        exact
        path="/loja/:slug/conteudo"
        component={StoreContent}
      />
      <PrivateRoute exact path="/loja/:slug/ofertas" component={StoreOffers} />
      <PrivateRoute exact path="/categorias" component={Categories} />
      <PrivateRoute exact path="/ofertas" component={Offers} />
    </Switch>
  </BrowserRouter>
)

export default Routes
