import React from 'react'
import { withRouter } from 'react-router'
import { PageHeader } from 'antd'
import styled from 'styled-components'
import TableCategories from '../../components/TableCategories'

const Content = styled.main`
  position: relative;
  margin: 24px;
  transition: all 0.2s;
`
const Categories = () => (
  <Content>
    <PageHeader
      ghost={false}
      title="Tabela de Categorias"
      subTitle="Categorias existentes no sistema"
    />
    <TableCategories></TableCategories>
  </Content>
)

export default withRouter(Categories)
