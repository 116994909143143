import React from 'react'
import { useQuery, useQueryCache, useMutation } from 'react-query'
import api from '../../../services/api'
import {
  Typography, Tag, Divider
} from 'antd'

import AddCategory from './AddCategory'

const { Title } = Typography


const getCategories = async storeId => {
  const { data } = await api.get(`stores/${storeId}/category_stores`)
  return data
}


export default function Categories({ storeId }) {
  const { data, isLoading, isError } = useQuery(['categories', storeId], () => getCategories(storeId))

  const cache = useQueryCache()

  const [mutateDeleteCategory] = useMutation(
    id => api.delete(`stores/${storeId}/category_stores/${id}`),
    {
      // Optimistically update the cache value on mutate, but store
      // the old value and return it so that it's accessible in case of
      // an error
      onMutate: () => {
        cache.cancelQueries('categories')

        const previousValue = cache.getQueryData('categories')

        cache.setQueryData('categories', old => [...old])

        return previousValue
      },
      // On failure, roll back to the previous value
      onError: (err, variables, previousValue) =>
        cache.setQueryData('categories', previousValue),
      // After success or failure, refetch the todos query
      onSettled: () => {
        cache.invalidateQueries('categories')
      },
    },
  )

  if(isLoading) {
    return "Carregando..."
  }


  if(isError) {
    return "Erro ao carregar categorias"
  }

  return (
    <>
      <Title level={3}>Categorias</Title>
      <AddCategory storeId={storeId} />
      <Divider />
      {data.map(category => <Tag closable onClose={() => mutateDeleteCategory(category.id)}>{category.category.title}</Tag>)}
    </>
  )
}
