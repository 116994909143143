import React from 'react'
import { withRouter } from 'react-router'
import { PageHeader } from 'antd'
import styled from 'styled-components'
import TablePlataforms from '../../components/TablePlataforms'

const Content = styled.main`
  position: relative;
  margin: 24px;
  transition: all 0.2s;
`
const Plataforms = () => (
  <Content>
    <PageHeader
      ghost={false}
      title="Tabela de Plataformas"
      subTitle="Plataformas existentes no sistema"
    />
    <TablePlataforms></TablePlataforms>
  </Content>
)

export default withRouter(Plataforms)
