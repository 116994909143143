import React from 'react'
import { withRouter } from 'react-router'
import { PageHeader } from 'antd'
import styled from 'styled-components'
import TableTags from '../../components/TableTags'

const Content = styled.main`
  position: relative;
  margin: 24px;
  transition: all 0.2s;
`
const Tags = () => (
  <Content>
    <PageHeader
      ghost={false}
      title="Tabela de Tags"
      subTitle="Tags existentes no sistema"
    />
    <TableTags></TableTags>
  </Content>
)

export default withRouter(Tags)
