import axios from 'axios'
import {
  getToken,
  setToken,
  setClient,
  getClient,
  getUid,
  logout,
} from '../services/auth'

export const apiUrl = 'https://atlas.daazcavernas.com'

const requestInterceptor = [
  config => {
    if (config.url === 'sign_in') {
      return config
    } else {
      const actualToken = getToken()
      const uid = getUid()
      const client = getClient()
      config.headers['access-token'] = actualToken
      config.headers['uid'] = uid
      config.headers['client'] = client
      //config.headers['Pragma'] = 'no-cache'
      config.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate'
      return config
    }
  },
  error => {
    return Promise.reject(error)
  },
]

const responseInterceptor = [
  response => {
    const actualToken = getToken()
    const client = response.headers['client']
    const token = response.headers['access-token']

    if (token && token !== actualToken) {
      setToken(token)
      setClient(client)
    }

    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const actualToken = getToken()
    const token = error.response.headers['access-token']
    const client = error.response.headers.client
    if (token && token !== actualToken) {
      setToken(token)
      setClient(client)
    }
    if (error.response.status === 401) {
      logout()
    }
    return Promise.reject(error)
  },
]

const api = axios.create({
  baseURL: apiUrl + '/admin',
})

api.interceptors.request.use(...requestInterceptor)
api.interceptors.response.use(...responseInterceptor)

export default api

export const apiShop = axios.create({
  baseURL: apiUrl + '/api_shop',
})

apiShop.interceptors.request.use(...requestInterceptor)
apiShop.interceptors.response.use(...responseInterceptor)

export const apiPlatforms = axios.create({
  baseURL: apiUrl + '/api_plataform',
})

apiPlatforms.interceptors.request.use(...requestInterceptor)
apiPlatforms.interceptors.response.use(...responseInterceptor)

export const apiOffer = axios.create({
  baseURL: apiUrl + '/api_offer',
})

apiOffer.interceptors.request.use(...requestInterceptor)
apiOffer.interceptors.response.use(...responseInterceptor)
