import React, { useState, useEffect } from 'react'
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Tag,
  message,
  Select,
  Space,
  Badge,
} from 'antd'
import {
  EditOutlined,
  DeleteOutlined,
  LinkOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons'

import { fetchData, postData, deleteData, putData } from '../../utils/request'
import dayjs from 'dayjs'
import ColorHash from 'color-hash'

const { Option } = Select
const colorHash = new ColorHash({ saturation: 0.4 })

async function fetchPlataformsFromServer() {
  const res = await fetchData('/plataforms')
  return res
}

async function fetchTypesFromServer() {
  const res = await fetchData('/types')
  return res
}

const EditableCell = ({
  editing,
  dataIndex,
  inputType,
  record,
  index,
  children,
  availableTypes,
  ...restProps
}) => {
  const inputNode =
    inputType === 'select' ? (
      <Select placeholder="Selecione um tipo">
        {availableTypes.map(type => (
          <Option key={type.id} value={type.id}>
            {type.name}
          </Option>
        ))}
      </Select>
    ) : (
      <Input />
    )

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Preencha este campo!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

const EditableTable = ({
  fetchPlataforms = fetchPlataformsFromServer,
  fetchTypes = fetchTypesFromServer,
}) => {
  const [form] = Form.useForm()
  const [data, setData] = useState([])
  const [editingKey, setEditingKey] = useState('')
  const [types, setTypes] = useState([])

  useEffect(() => {
    fetchTypes().then(function updateStateTypes(data) {
      setTypes(data)
    })
  }, [fetchTypes])

  useEffect(
    function fetchCharactersOnStart() {
      fetchPlataforms().then(function updateStateWithPlataforms(data) {
        setData(data)
      })
    },
    [fetchPlataforms],
  )

  const isEditing = record => record.id === editingKey

  const edit = record => {
    form.setFieldsValue({
      title: '',
      type_id: '',
      ...record,
    })
    setEditingKey(record.id)
  }

  const cancelNewRow = () => {
    const filterNew = data.filter(item => item.id !== 0)
    setData(filterNew)
    setEditingKey('')
  }

  const cancel = () => {
    setEditingKey('')
  }

  const handleDelete = id => {
    const newData = data.filter(item => item.id !== id)
    setData(newData)
    deleteData('/plataforms', id)
    message.success('Excluido com sucesso')
  }

  const handleAdd = () => {
    const temporaryId = 0
    const newData = {
      id: temporaryId,
    }
    setData([newData, ...data])
    setEditingKey(temporaryId)
  }

  const save = async key => {
    try {
      const row = await form.validateFields()
      const isANewRow = key === 0 ? true : false

      if (isANewRow) {
        const { name, type_id } = row
        const dataPost = { name, type_id }
        const response = await postData('/plataforms', dataPost)
        if (response.id) {
          const newData = [response, ...data]
          const filterNew = newData.filter(item => item.id !== 0)
          setData(filterNew)
          setEditingKey('')
          message.success('Plataforma criada')
        } else {
          message.error('Erro ao criar')
        }
      } else {
        const response = await putData('/plataforms', row, key)
        if (response.id) {
          message.success('Plataforma atualizada')
          const newData = [...data]
          const index = newData.findIndex(item => key === item.id)
          if (index > -1) {
            const item = response
            newData.splice(index, 1, { ...item, ...row })
            setData(newData)
            setEditingKey('')
          } else {
            newData.push(row)
            setData(newData)
            setEditingKey('')
          }
        } else {
          message.error('Erro ao atualizar')
        }
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      rowKey: 'id',
      width: '3%',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      width: '30%',
      editable: true,
      render: (_, record) => (
        <>
          <Badge color={colorHash.hex(`${record.name}`)} />
          {record.name}{' '}
          <a href={record.url} target="_blank" rel="noopener noreferrer">
            <LinkOutlined />
          </a>
        </>
      ),
    },

    {
      title: 'URL',
      dataIndex: 'url',
      editable: true,
      width: '10%',
    },
    {
      title: 'Tipo',
      dataIndex: 'type_id',
      width: '20%',
      editable: true,
      render: (_, record) => (
        <>
          {record.newRow && (
            <Select defaultValue="Selecione" style={{ width: 120 }}>
              {types.map(type => (
                <Option value={type.id}>{type.name}</Option>
              ))}
            </Select>
          )}
          {record.type ? <Tag>{record.type.name}</Tag> : 'Sem tipo'}
        </>
      ),
    },
    {
      title: 'Lojas cadastradas',
      dataIndex: 'stores_count',
      width: '8%',
    },
    {
      title: 'Data de Criação',
      dataIndex: 'created_at',
      width: '15%',
      render: date => {
        return dayjs(date).format('DD/MM/YYYY HH:mm')
      },
    },
    {
      title: 'Ação',
      render: (_, record) => {
        const editable = isEditing(record)
        return editable || record.newRow ? (
          <span>
            <a
              href="#/"
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              <CheckCircleOutlined style={{ fontSize: 18 }} />
            </a>
            <Popconfirm
              title="Tem certeza que deseja cancelar?"
              onConfirm={record.id === 0 ? cancelNewRow : cancel}
            >
              <CloseCircleOutlined style={{ fontSize: 18 }} />
            </Popconfirm>
          </span>
        ) : (
          <Space>
            <a
              href="#/"
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
            >
              <EditOutlined style={{ fontSize: 18 }} />
            </a>
            <Popconfirm
              title="Tem certeza que deseja excluir esta plataforma?"
              onConfirm={() => handleDelete(record.id)}
            >
              <DeleteOutlined style={{ fontSize: 18 }} />
            </Popconfirm>
          </Space>
        )
      },
    },
  ]
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === 'type_id' ? 'select' : 'text',
        dataIndex: col.dataIndex,
        name: col.name,
        editing: isEditing(record),
        availableTypes: types,
      }),
    }
  })
  return (
    <>
      <Button
        onClick={handleAdd}
        type="primary"
        disabled={editingKey !== ''}
        style={{
          marginBottom: 16,
          marginTop: 16,
        }}
      >
        Adicionar Plataforma
      </Button>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>
    </>
  )
}

export default EditableTable
