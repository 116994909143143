import React from 'react'
import Routes from './configs/routes'
import { QueryCache, ReactQueryCacheProvider } from 'react-query'
import './App.less'

const queryCache = new QueryCache()

const App = () => (
  <ReactQueryCacheProvider queryCache={queryCache}>
    <Routes />
  </ReactQueryCacheProvider>
)

export default App
